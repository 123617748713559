@use '@styles/dropshadow';

/* Removes the up / down arrows added by the browser for Number inputs */
/* From https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp */

.no-input-arrows {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.colorshadow {
  box-shadow: dropshadow.$softPurpleDropShadow;
}
