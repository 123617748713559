.blur {
  animation: blur 2s ease 0s infinite;
}

.centered-blur {
  align-items: center;
  animation: blur 2s ease 0s infinite;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@keyframes blur {
  100% {
    filter: blur(25px);
  }
  50% {
    filter: blur(7px);
  }
  0% {
    filter: blur(25px);
  }
}
